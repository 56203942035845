export default {
    locale: 'zh-TW',
    language: '繁體中文',
  nav: {
    home: '首頁',
    about: '關於我們',
    duty: '免責聲明',
    login: '登錄',
    user: '用戶',
    report: '舉報',
    admin: '後臺管理'
  },
  error: {
    400: '請求錯誤',
    401: '未授權，請登錄',
    403: '拒絕訪問',
    404: '請求錯誤',
    408: '請求超時',
    500: '服務器內部錯誤',
    501: '服務器內部錯誤',
    502: '網關錯誤',
    503: '服務不可用',
    504: '網關超時',
    505: 'HTTP版本不受支持',

    1000000: '獲取數據失敗！',
    1010000: '未登錄！',
    1011000: '令牌失效！',
    1012000: '無權訪問！',

    // 1020000: '參數錯誤！',
    1020003: '密碼格式不正確！',
    1020004: '帳號未註冊！',
    1020005: '密碼錯誤！',

    1020014: '驗證碼錯誤！',
    1020015: '郵箱地址錯誤！',
    1020022: 'SN碼錯誤！',
    1020023: 'SN已經被使用！',
    1020024: '沒有綁定SN！',
    1020025: '授權過期！',

    1021000: '文件上傳錯誤！',
    1021002: '文件不存在！',

    1100000: '服務器異常，請重試！',
    1100001: '操作超時！',

    1200000: '服務器維護中！'
  },
  app: {
    copyright: 'Copyright © 2021-2022 搜咖科技股份有限公司 版權所有',
    qc_scan: '掃碼登入',
    qc_fail: '獲取失敗！'
  },
  page: {
    title: '',
    home: {
      welcome_new: '歡迎新會員',
      lb_topic: '主題',
      lb_post_num: '帖數'
    },
    login: {
      login: '登錄',
      forgot: '忘記密碼?',
      state_code: '區  號：',
      mobile: '手機號：',
      pwd: '密  碼',
      no_statecode: '請選擇區號！',
      no_mobile: '請填寫手機號！',
      no_pwd: '請輸入密碼！',
      login_fail: '登入失敗！'
    },
    user: {
      title: '用戶信息',
      tip_head: '点击更换头像',
      title_head: '更换头像',
      tip_nick: '点击更新昵称',
      lb_uname: '帐号',
      lb_nick: '昵稱',
      lb_sex: '性别',
      v_sex_m: '男',
      v_sex_f: '女',
      lb_last_ip: '最後登錄IP'
    },
    publish: {
      tip_topic_type: '請選擇主題分類！',
      tip_topic_title: '標題不能為空！',
      tip_post_content: '內容不能為空！',
      tip_post_toolarge: '内容太大！',

      share_url_tip: '分享鏈接',
      share_name_tip: '標簽',
      share_icon_tip: '圖標URL',

      btn_modify: '修改',
      btn_publish: '發表'
    },
    forum: {
      btn_topic: '發帖',
      lb_publish_topic: '發表主題'
    },
    topic: {
      lb_reply: '回覆',
      lb_edit_post: '修改帖子',
      lb_review: '查看',
      v_ts: '樓主',
      v_post_deleted: '內容已刪除',
      lb_edit_at: '修改於',
      lb_del_at: '刪除於',
      alert_del_topic: '刪除主題將同時刪除所有回覆！',
      btn_reply: '回覆',
      btn_top: '置頂',
      btn_move: '移動',
      btn_reply_topic: '回覆主題',
      lb_post: '帖子',
      lb_topic: '主題',
      lb_score: '積分',
      lb_reg_time: '註冊時間',
      lb_alive_time: '在線時間'
    },
    report: {
      alert_title: '提交成功',
      alert_content: '感謝您為本論壇的支持，我們將盡快核查處理！'
    },
    nodata: '沒有數據',
    data_loading: '正在努力加載...',
    no_auth_opt: '對不起，您無權執行此操作！',
    no_auth_view: '對不起，您無權查看此內容！',
    page_prev: '上一頁',
    page_next: '下一頁',
    lh_order_new: '最新',
    lh_order_hot: '熱門',
    lh_topic: '主題',
    lh_author: '作者',
    lh_stats: '回覆/查看',
    lh_last: '最後回覆'
  },
  common: {
    alert_title: '溫馨提示',
    confirm_delete: '確認刪除？',
    today: '今日',
    yesterday: '昨日',
    topic: '主題',
    post: '帖子',
    user: '用戶',
    master: '版主',
    logining: '正在登入...',
    btn_save: '保存',
    btn_edit: '修改',
    btn_del: '刪除',
    btn_report: '舉報',
    tip_del_ok: '刪除成功！',
    tip_del_fail: '刪除失敗！'
  },
  admin: {
    logout: '退出管理',
    menu_forum: '版塊',
    menu_topic: '主題/帖子',
    menu_user: '用戶',
    menu_group: '用戶組'
  }
}
