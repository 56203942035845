const env = process.env.NODE_ENV;
const release = true;

const baseUrls = [
  'https://api.atlasai.tv',
  'https://dd.atlasai.tv'
];
const serverIndex = env === 'development' || !release ? 1 : 0;// 0: release 1: test server
const Setting = {
  demo: serverIndex > 0,
  apiBaseURL: baseUrls[serverIndex],

  // 网页标题的后缀
  titleSuffix: '',

  apiTimeout: 20,

  // 接口请求返回错误时，弹窗的持续时间，单位：秒
  modalDuration: 5,
  // 接口请求返回错误时，弹窗的类型，可选值为 Message 或 Notice
  errorModalType: 'Message', // 'Notice',
  // 错误提示弹窗标题
  errorModalTitle: 'Alert',
  // 错误提示内容
  errorDescriptions: {},
  // 错误显示
  showError: (/* message, code */) => {},

  appBridge: () => {
    return window && window.HTMLOBJ
  },
  // Cookies 默认保存时间，单位：天
  cookiesExpireDays: 15,
  cookiesPrefix: 'admin',

  /**
   * 多语言配置
   * */
  i18n: {
    // 默认语言
    default: 'zh-TW',
    // 是否根据用户电脑配置自动设置语言（仅第一次有效）
    auto: true
  }
};

export default Setting;
