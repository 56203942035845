<template>
  <div id="app">
    <div class="-header" style="display: table; width: 100%;text-align: left;">
      <span style="display: table-cell;">
        <SogaLink><router-link to="/">
          <img align="top" alt="Atlas logo" src="@/assets/logo.png">
        </router-link></SogaLink>
      </span>
      <div style="display: table-cell; text-align: right; vertical-align: top;">
        <span v-if="inSoga && $store.state.user">
          <a @click="onQCScan">{{$t('app.qc_scan')}}</a><span class="seq">|</span>
        </span>
        <SogaLink><router-link to="/duty">{{$t('nav.duty')}}</router-link></SogaLink><span class="seq">|</span>
        <!--<SogaLink><router-link to="/about">{{$t('nav.about')}}</router-link></SogaLink>-->
        <SogaLink><a href="https://www.tvsoga.com" target="_blank">{{$t('nav.about')}}</a></SogaLink>
        <span v-if="!$store.state.inSoga">
          <br v-if="narrow">
          <span v-else class="seq">|</span>
          <span v-if="$store.state.gettingUser">{{$t('common.logining')}}</span>
          <span v-else-if="$store.state.user">
            <UserName :user="$store.state.user" :nomaster="true"></UserName>
            <a v-if="showAdminExit" @click="logoutAdmin">{{$t('admin.logout')}}</a>
            <router-link v-if="showAdminEnter"
                         to="/admin">{{$t('nav.admin')}}</router-link>
          </span>
          <router-link v-else to="/login">{{$t('nav.login')}}</router-link>
        </span>
      </div>
    </div>
    <div id="nav">
      <Breadcrumb>
        <BreadcrumbItem to="/">
          <Icon type="ios-home-outline"></Icon>
          {{$t('nav.home')}}
        </BreadcrumbItem>
        <BreadcrumbItem v-for="(nav, ni) in $store.state.nav" :key="ni" :to="nav.isLast ? '':nav.route">
          {{nav.label}}
        </BreadcrumbItem>
      </Breadcrumb>
    </div>
    <router-view style="min-height: 50vh;"></router-view>
    <Divider></Divider>
    <div class="-footer" style="text-align: center;">
      <p style="margin-top: 5px;">{{$t('app.copyright')}}</p>
    </div>
    <!-- 扫码 -->
    <Modal v-if="inSoga" v-model="showQC" width="300" footer-hide>
      <p slot="header" style="color:#f60;text-align:center">
        <Icon type="ios-qr-scanner"></Icon>{{$t('app.qc_scan')}}({{qcCountSecs}}s)
      </p>
      <p v-if="qcUrl" style="text-align: center;">
        <vueQr :text="qcUrl" :size="200" :margin="0" :logoSrc="logo"></vueQr>
      </p>
    </Modal>
  </div>
</template>

<script>
  import { httpGet } from '@/api/data';
  import SogaLink from './views/parts/soga_link';
  import UserName from './views/parts/user_name';
  import vueQr from 'vue-qr';

  export default {
    name: 'Home',
    components: {
      SogaLink,
      UserName,
      vueQr
    },
    data () {
      return {
        narrow: false,
        inSoga: this.$store.state.inSoga,
        qcTime: 0,
        qcCountSecs: 0,
        qcUrl: null,
        showQC: false,
        logo: require('@/assets/logo.png')
      }
    },
    methods: {
      onQCScan () {
        const stamp = new Date().getTime();
        if (this.qcTime > stamp) {
          this.countQC(stamp);
          this.showQC = true;
          return;
        }

        this.qcCountSecs = 5 * 60;
        this.qcTime = stamp + this.qcCountSecs * 1000;
        httpGet('/v1/user/auto/code', null, { errors: this.$t('app.qc_fail') })
          .then(res => {
            let prefix = '?';
            const url = window.location.href;
            if (url.indexOf('?') > 0) {
              prefix = '&';
            }
            this.qcUrl = url + prefix + 'auth=' + res;
            this.showQC = true;
            this.countQC();
          }).catch(() => {
          });
      },
      countQC (stamp) {
        if (!(stamp > 0)) {
          stamp = new Date().getTime();
        }
        const tt = this.qcTime - stamp;
        if (tt <= 0) {
          this.qcCountSecs = 0;
          this.showQC = false;
        } else {
          this.qcCountSecs = Math.floor(tt / 1000);
          setTimeout(this.countQC, 1000);
        }
      },
      logoutAdmin () {
        this.$store.commit('enterAdmin', false);
        this.$router.push('/');
      }
    },
    computed: {
      showAdminExit () {
        if (this.$route.meta.isLogin || this.$route.name !== 'admin') {
          return false;
        }
        return this.$store.state.admin.loginTime > new Date().getTime();
      },
      showAdminEnter () {
        if (this.$route.meta.isLogin || this.$route.name === 'admin') {
          return false;
        }
        const user = this.$store.state.user;
        if (!user) {
          return false;
        }
        return user.gid > 0 && user.gid < 100;
      }
    },
    created () {
      this.narrow = window.screen.width < 730;
      if (!this.inSoga) {
        const auth = this.$route.query.auth;
        if (auth) {
          this.$router.push({
            name: 'login',
            query: {
              auth: auth
            }
          });
        }
      }
    }
  };
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  padding: 5px;
  min-height: 100vh;
}

#nav {
  width: 100%;
  text-align: left;
  margin-bottom: 5px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
  span {
    color: darkblue;
  }
  .ivu-breadcrumb-item-separator {
    margin: 0;
    color: gray;
  }
}

span.seq {
  color: lightgray;
  padding-left: 2px;
  padding-right: 2px;
}

.master {
  background-color: orangered;
  color: white;
  font-size: xx-small;
  padding: 1px;
  border: lightgray solid 1px;
}
.master-supper {
  background-color: goldenrod;
}
</style>
